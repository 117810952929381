@font-face {
  font-family: 'Roboto-Regular';
  src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Regular.woff") format("woff"), url("fonts/Roboto-Regular.ttf") format("truetype"), url("fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url("fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium.woff") format("woff"), url("fonts/Roboto-Medium.ttf") format("truetype"), url("fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url("fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Bold.woff") format("woff"), url("fonts/Roboto-Bold.ttf") format("truetype"), url("fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --blue: #0d1cce;
  --blue-rgb: 13,28,206;
  --blackis: #00042b;
  --bs-light-rgb:251, 250, 255;
}

body {
  font-family: 'Roboto-Regular', sans-serif;
  overflow-x: hidden;
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #ccc;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--blue);;
  -webkit-box-shadow: inset 0 0 0 1px #212529;
          box-shadow: inset 0 0 0 1px #212529;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 0px;
  background-color: #000;
  -webkit-box-shadow: inset 0 0 0 1px #212529;
          box-shadow: inset 0 0 0 1px #212529;
}

.l {
  -webkit-box-shadow: inset 0px 0px 5px red;
          box-shadow: inset 0px 0px 5px red;
}

/* Start | Pre-defined Style */
.bi {
  stroke: unset;
  stroke-width: 0px;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -.125em;
  fill: currentColor;
}

.bi-2x {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.bi-3x {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.bi-4x {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.bi-5x {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.bi-6x {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.bi-7x {
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
}

.bi-8x {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
}

.bi-9x {
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}

.bi-10x {
  -webkit-transform: scale(1.9);
          transform: scale(1.9);
}

.bi-15x {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.bi-20x {
  -webkit-transform: scale(1.9);
          transform: scale(1.9);
}

.fs-10px {
  font-size: 10px !important;
}

.fs-11px {
  font-size: 11px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-14d5px {
  font-size: 14.5px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-15d5px {
  font-size: 15.5px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-16d5px {
  font-size: 16.5px !important;
}

.fs-17px {
  font-size: 17px !important;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-19px {
  font-size: 19px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-21px {
  font-size: 21px !important;
}

.fs-22px {
  font-size: 22px !important;
}

.fs-23px {
  font-size: 23px !important;
}

.fs-24px {
  font-size: 24px !important;
}

.fs-25px {
  font-size: 25px !important;
}

.fs-26px {
  font-size: 26px !important;
}

.fs-27px {
  font-size: 27px !important;
}

.fs-28px {
  font-size: 28px !important;
}

.fs-29px {
  font-size: 29px !important;
}

.fs-30px {
  font-size: 30px !important;
}

.fw-medium, .fw-500 {
  font-family: 'Roboto-Medium';
}

.fw-bold {
  font-family: 'Roboto-Bold';
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-12 {
  border-radius: 12px;
}

.rounded-14 {
  border-radius: 14px;
}

.rounded-15 {
  border-radius: 15px;
}

.img-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.section-fill {
  min-height: calc(100vh - 68px - 54px);
}

.opacity-5 {
  opacity: 0.05;
}

.opacity-10 {
  opacity: 0.10;
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-20 {
  opacity: 0.20;
}

.opacity-30 {
  opacity: 0.30;
}

.opacity-33 {
  opacity: 0.33;
}

.opacity-35 {
  opacity: 0.35;
}

.opacity-40 {
  opacity: 0.40;
}

.opacity-45 {
  opacity: 0.45;
}

.opacity-60 {
  opacity: 0.60;
}

.opacity-65 {
  opacity: 0.65;
}

.opacity-70 {
  opacity: 0.70;
}

.opacity-80 {
  opacity: 0.80;
}

.opacity-85 {
  opacity: 0.85;
}

.opacity-90 {
  opacity: 0.90;
}

.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.20;
}

.zindex-0 {
  z-index: 0;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.zindex-4 {
  z-index: 4;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-50px {
  width: 50px;
}

.w-60px {
  width: 60px;
}

.w-70px {
  width: 70px;
}

.w-80px {
  width: 80px;
}

.w-90px {
  width: 90px;
}

.w-100px {
  width: 100px;
}

.w-110px {
  width: 110px;
}

.w-120px {
  width: 120px;
}

.h-2px {
  height: 2px !important;
}

.h-10px {
  height: 10px !important;
}

.h-15px {
  height: 15px !important;
}

.h-20px {
  height: 20px !important;
}

.h-25px {
  height: 25px !important;
}

.h-30px {
  height: 30px !important;
}

.h-35px {
  height: 35px !important;
}

.h-36px {
  height: 36px !important;
}

.h-38px {
  height: 38px !important;
}

.h-39px {
  height: 39px !important;
}

.h-40px {
  height: 40px !important;
}

.h-45px {
  height: 45px !important;
}

.min-w30px {
  min-width: 30px;
}

.min-w38px {
  min-width: 38px;
}

.min-w40px {
  min-width: 40px;
}

.min-w42px {
  min-width: 42px;
}

.min-w44px {
  min-width: 44px;
}

.min-w46px {
  min-width: 46px;
}

.wh-20px {
  width: 20px;
  height: 20px;
}

.min-wh-20px {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}

.mmw-20px {
  min-width: 20px;
  max-width: 20px;
}

.mmw-24px {
  min-width: 24px;
  max-width: 24px;
}

.mmw-60px {
  min-width: 60px;
  max-width: 60px;
}

.mmw-70px {
  min-width: 70px;
  max-width: 70px;
}

.mmw-80px {
  min-width: 80px;
  max-width: 80px;
}

.mmw-100px {
  min-width: 100px;
  max-width: 100px;
}

.mmw-120px {
  min-width: 120px;
  max-width: 120px;
}

.mmw-130px {
  min-width: 130px;
  max-width: 130px;
}

.mmw-140px {
  min-width: 140px;
  max-width: 140px;
}

.mmw-150px {
  min-width: 150px;
  max-width: 150px;
}

.mmw-160px {
  min-width: 160px;
  max-width: 160px;
}

.mmw-170px {
  min-width: 170px;
  max-width: 170px;
}

.mmw-180px {
  min-width: 180px;
  max-width: 180px;
}

.mmw-185px {
  min-width: 185px;
  max-width: 185px;
}

.mmw-190px {
  min-width: 190px;
  max-width: 190px;
}

.mmw-200px {
  min-width: 200px;
  max-width: 200px;
}

.mmw-210px {
  min-width: 210px;
  max-width: 210px;
}

.mmw-220px {
  min-width: 220px;
  max-width: 220px;
}

.mmwh-50 {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}

.mmwh-60 {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
}

.mmwh-80 {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
}

.mmwh-100 {
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
}

.maxw-200px {
  max-width: 200px;
}

.maxw-220px {
  max-width: 220px;
}

.maxw-250px {
  max-width: 250px;
}

.maxw-300px {
  max-width: 300px;
}

.maxw-310px {
  max-width: 310px;
}

.maxw-320px {
  max-width: 320px;
}

.maxw-340px {
  max-width: 340px;
}

.maxw-350px {
  max-width: 350px;
}

.maxw-360px {
  max-width: 360px;
}

.maxw-380px {
  max-width: 380px;
}

.maxw-390px {
  max-width: 390px;
}

.maxw-400px {
  max-width: 400px;
}

.mt-1px {
  margin-top: 1px;
}

.mt-2px {
  margin-top: 2px;
}

.mt-3px {
  margin-top: 3px;
}

.mb-1px {
  margin-bottom: 1px;
}

.mb-2px {
  margin-bottom: 2px;
}

.mb-3px {
  margin-bottom: 3px;
}

.mmb-10px {
  margin-bottom: -10px;
}

.mmb-15px {
  margin-bottom: -15px;
}

.mmb-20px {
  margin-bottom: -20px;
}

.m-1px {
  margin: 1px;
}

.m-2px {
  margin: 2px;
}

.m-3px {
  margin: 3px;
}

.m-4px {
  margin: 4px;
}

.m-5px {
  margin: 5px;
}

.pointer-none {
  pointer-events: none;
}

.text-orange {
  color: var(--bs-orange);
}

.text-blue {
  color: var(--blue);
}

.text-blackis {
  color: var(--blackis);
}

.bg-blue {
  background: var(--blue);
}

.bg-blackis {
  background: var(--blackis);
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff;
}

.anchor-bluehover {
  color: var(--blue);
  text-decoration: none;
}

.anchor-bluehover:hover {
  color: blueviolet;
  text-decoration: underline;
}

.ratio-18x9 {
  --bs-aspect-ratio: calc(9 / 18.45 * 100%);
}

.btn-tiled {
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg);
  letter-spacing: 0.5px;
}

.form-check-input:checked {
  background-color: var(--blue);
  border-color: var(--blue);
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.table {
  border-color: #e9e9ef;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.030);
}

.table > :not(:first-child) {
  border-top: none;
}

.table thead th {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.btn-xs {
  font-size: 13.5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dropdown-item {
  font-size: 15px;
  padding: .3rem 0.85rem;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--blue);
}

.pagination-sm .page-item .page-link {
  padding: .25rem .65rem;
  font-size: .875rem;
  color: #444;
}

.pagination-sm .page-item.active .page-link {
  color: #fff;
  background-color: var(--blue);
  border-color: var(--blue);
}

.btn-primary {
  background-color: var(--blue);
  border-color: var(--blue);
}

.btn-primary:hover {
  background-color: var(--blue);
  opacity: 0.9;
}

.btn-outline-primary {
  color: var(--blue);
  border-color: var(--blue);
}

.btn-outline-primary:hover {
  border-color: var(--blue);
  background-color: var(--blue);
}

.form-control-file {
  border-style: dashed;
  position: relative;
  min-height: 86px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.form-control-file:hover {
  -webkit-box-shadow: inset 0 0 6px #ddd;
          box-shadow: inset 0 0 6px #ddd;
}

.form-control-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.form-control-file .form-placehoder {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 0;
  pointer-events: none;
}

.form-control-file .form-placehoder::after {
  content: attr(data-title);
  font-size: 13px;
  opacity: 0.7;
  display: block;
}

.form-fileview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form-fileview .form-image {
  position: relative;
  z-index: 3;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 4px;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 5px 5px 0 0;
  -webkit-box-shadow: 0 0 0 1px #ced4da;
          box-shadow: 0 0 0 1px #ced4da;
}

.form-fileview .form-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}

.form-fileview .form-image button {
  position: absolute;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(243, 99, 99, 0.85);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  top: 0px;
  right: 0px;
}

.form-fileview .form-image button:hover {
  background-color: #f04242;
}

.gredient-orange {
  background: linear-gradient(-45deg, #febf96, #fe7e95);
}

.gredient-blue {
  background: linear-gradient(-45deg, #8fc9f9, #1d8ce5);
}

.gredient-green {
  background: linear-gradient(-45deg, #82d9d1, #1bac96);
}

.gredient-purple {
  background: linear-gradient(-45deg, #d88aff, #9b56ff);
}

.gredient-gold {
  background: linear-gradient(-45deg, #f0cd81, #ca8b15);
}

.gredient-lightgreen {
  background: linear-gradient(-45deg, #69df83, #588b2a);
}

.gredient-lightpurple {
  background: linear-gradient(-45deg, #c386aa, #d40ac4);
}

.gredient-lightred {
  background: linear-gradient(-45deg, #f58282, #df2f38);
}

.border-purple {
  border: 1px solid #eae5ff !important;
}

.border-light {
  border: 1px solid #f2f2f2 !important;
}

/* End | Pre-defined Style */
.navbar-light {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.navbar-light .navbar-brand {
  font-family: cursive, Arial, Helvetica, sans-serif;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 1;
  color: #fff;
}

.navbar-light .navbar-brand img {
  width: 200px;
  opacity: .6;
  margin-left: 0px;
}

.navbar-light .navbar-brand span {
  display: inline-block;
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
}



.navbar-light .navbar-nav .nav-item {
  margin-left: 5px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  padding: .8rem 1rem;
}

.navbar-light .navbar-nav .nav-item .nav-link .bi {
  font-size: 22px;
  display: block;
  margin: 4px auto;
}

.navbar-light .navbar-nav .nav-item .nav-link.active, .navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: var(--blue);
}

.hero-section {
  padding: 50px 0;
  min-height: calc(55vh - 63.59px);
  overflow: hidden;
  position: relative;
}

.nav-pills-common .nav-item .nav-link {
  background-color: #fff;
  border: 1px solid #ddd;
}

.nav-pills-common .nav-item .nav-link .form-check-input {
  position: relative;
}

.nav-pills-common .nav-item .nav-link .form-check-input::before {
  content: '';
  width: 7px;
  height: 7px;
  background: var(--blue);
  position: absolute;
  border-radius: 20px;
  top: calc(50% - 4px);
  left: calc(50% - 3px);
  opacity: 0;
}

.nav-pills-common .nav-item .nav-link.active {
  background-color: var(--blue);
  border-color: var(--blue);
}

.nav-pills-common .nav-item .nav-link.active .form-check-input::before {
  opacity: 1;
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select {
  padding: 1rem 1rem;
  font-size: 1.025rem;
}

.ratio-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  color: #fff;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-transition: 300ms;
  transition: 300ms;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(#000));
  background: linear-gradient(transparent 40%, #000);
}

.text-in2line {
  overflow: hidden;
  clear: both;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  visibility: visible;
}

.card:hover .ratio-gradient {
  opacity: 0.5;
}

.image-tiled {
  -webkit-transform: skewX(-15deg);
          transform: skewX(-15deg);
  overflow: hidden;
}

.image-tiled img {
  display: block;
  -webkit-transform: skewX(15deg) scale(1.01);
          transform: skewX(15deg) scale(1.01);
}

.image-tiled.image-tiled-reverse {
  -webkit-transform: skewX(15deg);
          transform: skewX(15deg);
}

.image-tiled.image-tiled-reverse img {
  -webkit-transform: skewX(-15deg) scale(1.01);
          transform: skewX(-15deg) scale(1.01);
}

.card-tiled {
  -webkit-transform: skewX(-8deg);
          transform: skewX(-8deg);
  overflow: hidden;
  position: relative;
}

.card-tiled .no-tiled {
  display: block;
  -webkit-transform: skewX(8deg) scale(1.01);
          transform: skewX(8deg) scale(1.01);
}

.card-tiled.card-tiled-reverse {
  -webkit-transform: skewX(8deg);
          transform: skewX(8deg);
}

.card-tiled.card-tiled-reverse .no-tiled {
  -webkit-transform: skewX(-8deg) scale(1.01);
          transform: skewX(-8deg) scale(1.01);
}

.card-tiled:hover {
  opacity: 0.75;
  -webkit-transition: 350ms;
  transition: 350ms;
}

@media (min-width: 768px) {
  .row-cols-full > .col {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    max-width: 100%;
    -webkit-transition: -webkit-box-flex 500ms;
    transition: -webkit-box-flex 500ms;
    transition: flex 500ms;
    transition: flex 500ms, -webkit-box-flex 500ms, -ms-flex 500ms;
  }
  .row-cols-full .card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .row-cols-full .card .ratio-4x3 {
    max-width: 260px;
  }
  .row-cols-full .card .card-body {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .row-cols-full .card .card-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row-cols-full .card .card-footer .btn {
    white-space: nowrap;
    margin-top: 5px;
  }
}

.page-topheading {
  background: rgba(var(--blue-rgb), 0.05);
  border-left: 3px solid rgba(var(--blue-rgb), 0.25);
  letter-spacing: 1px;
  padding: 9px 30px 9px 12px;
  border-radius: 0 30px 30px 0;
}

.page-topheading .bi {
  opacity: 0.6;
}

.legend-heading {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 8px;
  z-index: 1;
}

.legend-heading::before {
  content: '';
  position: absolute;
  height: 5px;
  width: 100vw;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  left: 0;
  bottom: 0;
}

.hover-hightlight:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.list-group-item {
  border: 1px solid #e9e6f3;
}

/*Start | Datatable layout modified*/
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
  font-size: 0;
}

.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_length input, .dataTables_wrapper .dataTables_filter select, .dataTables_wrapper .dataTables_filter input {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_length input:focus, .dataTables_wrapper .dataTables_filter select:focus, .dataTables_wrapper .dataTables_filter input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dataTables_wrapper .dataTables_filter {
  display: inline-block;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter label {
  width: 100%;
  max-width: 280px;
}

.dataTables_wrapper .dataTables_filter label input {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 10px 7px;
  padding-left: 33px;
}

.dataTables_wrapper .dataTables_length label {
  font-size: 14px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dataTables_wrapper .dataTables_length label select {
  margin: 0 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .22rem center;
  background-size: 14px 10px;
  padding-right: 20px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.dataTables_wrapper .table {
  margin-bottom: 0;
  width: 100%;
}

.dataTables_wrapper .table thead .sorting {
  padding-right: 14px;
  position: relative;
}

.dataTables_wrapper .table thead .sorting::before, .dataTables_wrapper .table thead .sorting::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  right: 4px;
  margin-top: -6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid currentColor;
  opacity: 0.3;
}

.dataTables_wrapper .table thead .sorting::after {
  margin-top: 2px;
  border-bottom: 4px solid transparent;
  border-top: 6px solid currentColor;
}

.dataTables_wrapper .table thead .sorting.sorting_asc::before {
  opacity: 1;
  border-bottom-color: var(--blue);
}

.dataTables_wrapper .table thead .sorting.sorting_desc::after {
  opacity: 1;
  border-top-color: var(--blue);
}

.dataTables_wrapper .dataTables_info {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 7px;
  height: 30px;
  min-width: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  border: 1px solid transparent;
  margin-left: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px solid #dee2e6;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #212529;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-weight: 500;
  padding: 0 7px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: rgba(var(--blue-rgb), 0.15);
  border-color: rgba(var(--blue-rgb), 0.15);
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  top: 40px;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  padding-top: 40px;
  text-align: center;
  color: var(--blue);
  font-weight: 600;
  letter-spacing: 1px;
}

.dataTables_wrapper .dataTables_empty {
  text-align: center !important;
  color: red;
  padding: 50px 10px !important;
  opacity: 0.75;
}

/*End | Datatable layout modified*/
.form-icon-check {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.form-icon-check:hover .bi {
  opacity: 1;
}

.form-icon-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-icon-check input:checked ~ .bi {
  opacity: 1;
}

.form-icon-check .bi {
  opacity: 0.35;
  -webkit-transition: 350ms;
  transition: 350ms;
}

.accordion-sm .accordion-button::after {
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem;
}

footer.front-footer {
  position: relative;
  overflow: hidden;
  background-color: #00042b;
  color: rgba(255, 255, 255, 0.65);
  padding-top: 10px;
  padding-bottom: 35px;
}

footer.front-footer .list-unstyled a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.65);
}

footer.front-footer .list-unstyled a:hover {
  color: #fff;
}

/*Start | Admin Style */
header {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

header .logo {
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 3px 0 1px 0;
  color: var(--bs-black);
}

header .logo img {
  height: 48px;
  opacity: 0.60;
}

header nav {
  background-color: var(--blue);
  border: none;
}

header nav .nav .nav-item {
  margin-right: 42px;
}

header nav .nav .nav-item .nav-link {
  font-size: 15px;
  padding: 14px 0;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
  white-space: nowrap;
}

header nav .nav .nav-item .nav-link .bi {
  font-size: 16px;
  margin-right: 8px;
  opacity: 0.70;
}

header nav .nav .nav-item .nav-link.active, header nav .nav .nav-item .nav-link:hover, header nav .nav .nav-item .nav-link.show {
  color: #1dffea;
}

.dropdown-profile {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #212529;
}

.dropdown-profile .bi {
  font-size: 22px;
  opacity: 0.65;
}

.dropdown-profile span {
  margin-left: 8px;
}

.dropdown-profile.show, .dropdown-profile:hover {
  color: var(--blue);
}

.header-mobile {
  position: fixed;
  display: none;
  width: 100%;
  left: 0;
  bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 0;
  overflow: hidden;
  z-index: 100;
  background: #fbfaff;
  -webkit-box-shadow: 0 -1px 0 0.1px #e9e9ef;
          box-shadow: 0 -1px 0 0.1px #e9e9ef;
}

.header-mobile .footer-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 25%;
  height: 54px;
  color: #333;
  font-size: 12px;
  padding: 0;
  background: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header-mobile .footer-link.active {
  color: var(--blue);
}

.header-mobile .footer-link span {
  display: block;
  margin-top: 5px;
}

.header-mobile .footer-link .bi {
  font-size: 17px;
  margin-top: 2px;
}

.content-wrapper {
  min-height: calc(100vh - 101px - 23px);
  /*Minus Header+Footer height*/
}

.table-lasttd-center tr td:last-child {
  text-align: center;
}

.table-lasttd-end tr td:last-child {
  text-align: right;
}

.card-tiles {
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 160px;
  height: 100%;
}

.toast-end .toast.show {
  opacity: 1;
  -webkit-animation: toastanim 0.350s linear forwards;
          animation: toastanim 0.350s linear forwards;
}

.toast-end .toast.hide {
  display: block;
  opacity: 1;
  -webkit-animation: toastanimreverse 0.350s linear forwards;
          animation: toastanimreverse 0.350s linear forwards;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.7);
  z-index: 120;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loader .spinner-border {
  width: 3rem;
  height: 3rem;
  border: .4em solid currentColor;
  border-top: transparent;
  border-bottom: transparent;
}

@-webkit-keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
  }
}

@keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
  }
}

.table-imgbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #e9e9ef;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  text-decoration: none;
}

.table-imgbox.table-img40 {
  width: 40px;
  height: 40px;
}

.table-imgbox.table-img50 {
  width: 50px;
  height: 50px;
}

.table-imgbox.table-img60 {
  width: 60px;
  height: 60px;
}

.table-imgbox.table-img80 {
  width: 80px;
  height: 80px;
}

.table-imgbox img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.my-profile {
  pointer-events: none;
}

.my-profile .form-control, .my-profile .form-select {
  padding-left: 0;
  border-radius: 0;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-bottom: 1px solid #eee;
  resize: none;
}

.my-profile .form-control:focus, .my-profile .form-select:focus {
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
}

.my-profile .form-select {
  background-image: none;
}

.my-profile .showon-active {
  display: none;
}

.my-profile [data-render="0"], .my-profile [data-render="1"] input:checked {
  display: none;
  margin-right: 0;
}

.my-profile [data-render="1"] {
  padding-left: 0;
}

.showon-active {
  display: inline-block;
}

.hideon-active {
  display: inline-block;
}

footer.admin-footer {
  background-color: #eaebed;
  font-size: 11px;
  line-height: 1;
  padding: 6px 0;
  color: #444;
}

.modal-backdrop.show {
  opacity: .75;
}

/*End | footer */
/*End | Admin Style */
/*=====================================*/
/* Start | Responsive Style*/
/*=====================================*/
.display-7 {
  font-size: calc(1.375rem + 1.25vw);
  line-height: 1.35;
}

.display-8 {
  font-size: calc(0.9rem + 1vw);
  line-height: 1.40;
}

@media (min-width: 1540px) {
  header .container-fluid, .content-wrapper .container-fluid, footer .container-fluid {
    padding-left: 125px;
    padding-right: 125px;
  }
}

@media (min-width: 1320px) {
  .content-wrapper .container {
    padding-left: 125px;
    padding-right: 125px;
  }
}

.row > .col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 1200px) {
  .display-7 {
    font-size: 2rem;
  }
  .display-8 {
    font-size: 1.4rem;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50%;
  }
  .w-sm-75 {
    width: 75%;
  }
  .w-sm-80 {
    width: 80%;
  }
}

@media (max-width: 1399.9px) {
  .navbar .container {
    max-width: 100%;
  }
}

@media (max-width: 1199.9px) {
  .navbar .navbar-brand img {
    width: 178px;
  }
  .navbar .navbar-brand span {
    -webkit-transform: translateY(11px);
            transform: translateY(11px);
  }
  .navbar .navbar-brand::before {
    width: 50%;
    height: 72px;
  }
  .navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 45px;
  }
  .navbar .navbar-nav .nav-item {
    width: 33.33%;
    margin: 0 -1px -1px 0;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .navbar .navbar-nav .nav-item:last-child {
    padding-top: 25px;
    padding-bottom: 5px;
    width: 100%;
    border: none;
  }
  .navbar .navbar-nav .nav-item:last-child .btn {
    margin-bottom: 5px;
  }
}

@media (max-width: 1024px) {
  header {
    height: 54px;
    border-bottom: 1px solid #e9e9ef;
  }
  header .logo {
    height: 54px;
    margin: 0;
  }
  header .logo img {
    height: 34px;
    opacity: 0.75;
  }
  header nav {
    position: absolute;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 0px;
    height: calc(100vh - 54px - 54px);
    right: 0;
    border-left: 1px solid #e9e9ef;
    border-top: none;
    border-bottom: none;
    overflow: hidden;
    overflow-y: auto;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
  }
  .header-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  body.menu-open {
    overflow: hidden;
  }
  body.menu-open .content-wrapper {
    opacity: 0.1;
    background: #fff;
  }
  body.menu-open header nav {
    width: 220px;
  }
  .content-wrapper {
    min-height: calc(100vh - 54px - 54px);
    /*Minus Header+Footer height*/
    padding-bottom: 64px;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
  }
  footer.admin-footer {
    display: none;
  }
}

.custom-year-filter .react-datepicker__year-text--disabled{
  display: none !important;
}

.react-datepicker{
  min-width: 150px;
}

@media (max-width: 575.9px) {
  .navbar .navbar-brand::before {
    width: 80%;
    height: 72px;
  }
  .dropdown-profile::after, .dropdown-profile span {
    display: none;
  }
}

@media (max-width: 480px) {
  .navbar .navbar-brand img {
    width: 150px;
    top: 18px;
  }
  .navbar .navbar-nav .nav-item {
    width: 50%;
  }
}


/* Write by vishal for google map */

.set-mapbox{
  position: relative;
}
#vehicle_map {
  padding-top: 50px;
  height: 350px;
  box-sizing: border-box;
}
#vehicle_map>div:nth-child(1){
  margin-top: 50px;
}
.pac-container{
  z-index: 1200 !important;
}
.gmap-search-field{
  left: 5px!important;
  top: -43px !important;
  width: calc(100% - 10px);
}





/* .pac-container {
  background-color: #FFF;
  z-index: 99;
  position: fixed;
  display: inline-block;
  float: left;
}



.vehicle_input_location {
  position: absolute !important;
  left: 0 !important;
  border: 1px solid #999;
}
.vehicle_input_location::placeholder {
  color: transparent;
} */


/*=====================================*/
/* End | Responsive Style*/
/*=====================================*/
/*# sourceMappingURL=style.css.map */


/*=====================================*/
/* Start | Vehicle Colour*/
/*=====================================*/


.beige {
  background-color: #F5F5DC;
}

.black {
  background-color: #000000;
}

.blue {
  background-color: #0000ff;
}

.bronze {
  background-color: #CD7F32;
}

.brown {
  background-color: #964B00;
}

.burgundy {
  background-color: #800020;
}

.dark-green {
  background-color: #006400;
}

.gold {
  background-color: #FFD700;
}

.green {
  background-color: #00ff00;
}

.grey {
  background-color: #808080;
}

.golden {
  background-color: #FFD700;
}

.granite-metallic {
  background-color: #676767;  /*  suspence */
}

.grey-metallic {
  background-color: #8E8E8E;
}

.gun-metallic {
  background-color: #8D918D;
}

.indigo {
  background-color: #4B0082;
}

.lavender {
  background-color: #E6E6FA;
}

.magenta {
  background-color: #FF00FF;
}

.maroon {
  background-color: #800000;
}

.medium-silver {
  background-color: #76828b;
}

.metallic {
  background-color: #d4af37;
}

.metallic-green {
  background-color: #296e01;
}

.metallic-grey {
  background-color: #8E8E8E;
}

.metallic-blue {
  background-color: #32527b;
}

.navy {
  background-color: #000080;
}

.olive-green {
  background-color: #BAB86C;
}

.olive-mica-metallic {
  background-color: #665949 ;   /*  suspence */
}

.orange {
  background-color: #FFA500;
}

.pear-white {
  background-color: #F8F6F0;
}

.panthera-metal {
  background-color: #3e3e3e;   /*  suspence */
}

.pearl-black {
  background-color: #09143C;
}

.pink {
  background-color: #FFC0CB;
}

.purple {
  background-color: #A020F0;
}

.red {
  background-color: #ff0000;
}

.red-wine {
  background-color: #990012;
}

.redwine {
  background-color: #990012;   /*  suspence */
}

.rose-metallic {
  background-color: #cd8a93;   /*  suspence */
}

.rose-mist {
  background-color: #a47370;   /*  suspence */
}

.rose-pink {
  background-color: #E7A1B0;
}

.rose-gold {
  background-color: #B76E79;
}

.rosemist {
  background-color: #a47370;   /*  suspence */
}

.silver {
  background-color: #C0C0C0;
}

.sand-beige {
  background-color: #CA9F7C;
}

.shalimar-rose {
  background-color: #c4b6be;   /*  suspence */
}

.sky-blue {
  background-color: #87CEEB;
}

.smoke-green {
  background-color: #a8bba2;
}

.super-white {
  background-color: #e8eaea;
}

.turquoise {
  background-color: #30D5C8;
}

.unlisted {
  background-color: #d4d4d8;   /*  suspence */
}

.urban-titanium-metallic {
  background-color: #524c4d;   /*  suspence */
}

.white {
  background-color: #FFFFFF;
}

.white-and-silver {
  background-color: #DADBDD;
}

.wine-red {
  background-color: #990012;
}

.yellow {
  background-color: #FFFF00;
}

.light-green {
  background-color: #90ee90;
}

.red-vine {
  background-color: #990012;   /*  suspence */
}

.smoke-grey {
  background-color: #708c98;
}


/*=====================================*/
/* End | Vehicle Colour*/
/*=====================================*/